@import "../mixins";

.pending {
  width: 100vw;
  height: 100vh;
  z-index: 1000000;
  left: 0;
  top: 0;
  position: fixed;
  background-color: rgba(51,65,85,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}

.pending-progress {
  .ant-progress-text {
    .loading {
      width: 150px;
      margin: auto;
      font-size: 18px;
      line-height: 2;
      text-align: center;
      color: #0e6472;
      text-shadow: #0084ff 1px 0 10px;
    }
  }
}
