@import "../../mixins";

.tender {
  padding: 20px;
  .buttons-left {
    justify-content: start;
  }
  .buttons-right {
    justify-content: right;
  }
  .tender-details, .ask-history, .ask {
    border: 2px solid #e0dfdf;
    border-radius: 8px;
    padding: 15px;
    h1 {
      font-size: 22px;
    }
    h2 {
      font-size: 18px;
      color: #7c7b7b;
    }
    h3 {
      font-size: 16px;
      color: $black;
    }
  }

  .tender-details {
    .description {
      font-size: 14px;
      font-weight: 100;
      line-height: 1.5;
      h1, h2 {
        font-size: 18px;
      }
    }
    .ant-table-wrapper {
      margin-bottom: 10px;
      .ant-table {
        background-color: #e8e9e9;
        .ant-table-content {
          .ant-table-thead {
            tr {
              th {
                background-color: #d8d9d9;
              }
            }
          }
        }
        .ant-table-tbody {
          tr {
            td {
              white-space: normal;
            }
          }
        }
      }
    }
    ul {
      padding-left: 0;
      line-height: 1.8;
      ol, li {
        list-style-position: inside;
        padding-left: 10px;
      }
    }
    .ant-tag {
      padding: 10px 30px;
      border-radius: 15px;
      font-size: 14px;
      color: $black;
      font-weight: bold;
      display: inline-flex;
      .ellipsis {
        display: inline-block;
        margin-inline-end: 4px;
        overflow: hidden;
        white-space: pre;
        text-overflow: ellipsis;
        max-width: 200px;
        font-size: 13px;
        color: $black;
      }
      .ant-tag-close-icon {
        margin-inline-start: 6px;
        font-size: 13px;
        color: #726f6f;
      }
    }
  }

  /* Shine */
  .shine:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(120deg, rgba(255,255,255, 0) 30%, rgba(255,255,255, .8), rgba(255,255,255, 0) 70%);
    top: 0;
    left: -100px;
    animation: shine 3s infinite linear; /* Animation */
  }

  /* animation */
  @keyframes shine {
    0% {left: -100px}
    20% {left: 100%}
    100% {left: 100%}
  }

}
