@import "../../mixins";

.profile {
  border-radius: 20px;
  .ant-radio-group {
    margin: 30px;
    .ant-radio-wrapper {
      padding: 12px 20px;
      font-size: 13px;
      color: $black;
      font-weight: bold;
      margin-inline-end: 30px;
      .ant-radio-inner {
        border-width: 0;
        background-color: #3E90F0;
        border-radius: 4px;
        width: 14px;
        height: 14px;
      }
    }
    .ant-radio-wrapper-checked {
      background-color: #e8f8f8;
      border-radius: 10px;
      .ant-radio-checked {
        .ant-radio-inner {
          background-color: #3FDD78;
        }
        .ant-radio-inner::after {
          transform: unset;
          opacity: 0;
        }
      }
    }
  }
  .content {

    .menu {
      h2 {
        font-size: 16px;
        color: #F5F6F6;
      }
      .link-button {
        span, svg {
          color: #6C7275;
        }
        border: none;
        justify-content: start;
        font-size: 13px;
        font-weight: bold;
      }
      .link-button.active {
        span, svg {
          color: #000000;
        }
        border: solid 2px #78b9f5;
      }
      .delete-account {
        span {
          color: #FF6C3E;
        }
        svg {
          color: #FF6C3E;
        }
      }
      .delete-account:hover {
        span {
          color: #ec5627;
        }
        svg {
          color: #d34315;
        }
      }
      .delete-account.active {
        border: solid 2px #FF6C3E;
        span {
          color: #FF6C3E;
        }
        svg {
          color: #FF6C3E;
        }
      }
      .part-one {
        padding: 55px 40px 15px 50px;
        width: 100%;
      }
      .part-two {
        padding: 10px 40px 20px 40px;
        width: 100%;
      }
      .part-three {
        padding: 10px 40px 30px 40px;
        width: 100%;
      }
      .ant-divider-horizontal {
        margin: 15px auto;
        width: 290px;
        min-width: unset;
        border-color: #E8ECEF;
      }
    }

    .item-wrapper {
      display: block;
      width: 100%;
      min-height: 100vh;
      .item {
        padding: 65px;
        h1 {
          font-size: 32px;
          font-weight: bold;
          color: $black;
        }
        h2 {
          font-size: 20px;
          color: $black;
        }
      }
    }
  }
}