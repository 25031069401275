@import "../mixins";

.sign-in {
  max-width: 400px;
  margin: 60px auto 0 auto;

  .ant-space {
    width: 100%;
  }

  .ant-divider-with-text {
    border-block-start: #acadaf;
    .ant-divider-inner-text {
      color: #acadaf;
      font-size: 12px;
      font-weight: bold;
    }
  }

  .ant-typography {
    max-width: 400px;
    text-align: center;
    font-weight: bold;
    color: #6C7275;
    margin: auto auto 20px;
    font-size: 38px;
  }

  .switch {
    font-weight: bold;
    color: #F5F6F6;
    font-size: 14px;
  }

  .logo {
    text-align: center;
    margin-bottom: 15px;
  }

  .switch-button {
    padding: 3px;
    background-color: #F3F5F7;
    border-radius: 12px;
    .ant-btn {
      height: 35px;
    }
  }

  @media only screen and (max-width: 1199px) {
    margin-top: 40px;
  }
}
