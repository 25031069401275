@import "../mixins";

.dashboard-layout, .sider-drawer {
  background-color: $background !important;
  min-height: 100vh;

  .ant-drawer-header {
    border-bottom-color: #F5F6F6;
    .ant-drawer-title, .ant-drawer-close {
      color: #F5F6F6;
    }
  }

  footer {
    background-color: transparent;
    text-align: center;
    color: $black;
  }

  .ant-menu-light {
    background: transparent;
    width: 100% !important;
    padding: 10px;
    border-inline-end: none !important;
    .ant-menu-item {
      padding: 3px !important;
      background: transparent !important;
      height: 45px;
      line-height: 45px;
      a {
        color: #F5F6F6;
        font-size: 15px;
      }
      .ant-menu-item-icon-wrapper {
        border-radius: 100%;
      }
      .ant-menu-item-icon {
        svg {
          line-height: 35px;
          margin: auto;
          display: block;
          padding: 8px;
          color: #F5F6F6;
          height: 35px;
          width: 35px;
        }
      }
    }
    .dashboard {
      .ant-menu-item-icon {
        svg {
          color: #0084FF;
          width: 40px;
          height: 40px;
        }
      }
    }
    .opportunities {
      .ant-menu-item-icon {
        svg {
          color: #4BAB71;
        }
      }
    }
    .bid-submission {
      .ant-menu-item-icon {
        svg {
          color: #3E90F0;
        }
      }
    }
    .new-search-filters {
      .ant-menu-item-icon {
        svg {
          color: #D84C10;
        }
      }
    }
    .saved-opportunities {
      .ant-menu-item-icon {
        svg {
          color: #C83FDD;
        }
      }
    }
    .help-center {
      .ant-menu-item-icon {
        svg {
          color: #F03E3E;
        }
      }
    }
    .decision-intelligence {
      .ant-menu-item-icon {
        svg {
          color: #8C6584;
        }
      }
    }
    .knowledge-base {
      .ant-menu-item-icon {
        svg {
          color: #BC1A4B;
        }
      }
    }
    .profile {
      .ant-menu-item-icon {
        svg {
          color: #FFC224;
        }
      }
    }
    .menu-item-collapsed.tender {
      height: 30px;
      line-height: 30px;
      .ant-menu-item-icon {
        margin-left: 10px;
      }
    }
    .ant-menu-item-selected.menu-item-collapsed {
      width: 45px;
      background: none !important;
      box-shadow: none !important;
    }
    .ant-menu-item-selected {
      background: linear-gradient(270deg, rgba(50, 55, 53, 0.96) 50%, rgba(70, 111, 109, 0.5) 100%) !important;
      box-shadow: 0 1px 0 0 #FFFFFF0D inset !important;
    }
  }

  .collapsed-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    img {
      width: 150px;
      height: 32px;
      margin-left: 10px;
      margin-top: 40px;
    }
    .collapsed {
      font-size: 16px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      left: 15px !important;
      margin-top: 40px;
      margin-bottom: 20px;
      color: #afb1b2;
      border: none;
    }
    .collapsed:hover {
      color: #6C7275;
    }
  }

  .collapsed-mobile {
    width: 40px;
    height: 40px;
    color: #F5F6F6;
    background: $background !important;
    border: none;
    border-radius: 0 5px 5px 0;
  }

  .sign-out {
    font-size: 16px;
    min-width: 40px;
    height: 40px;
    margin-left: 10px;
  }

  .sider-desktop {
    background: transparent;
  }

 .layout-auth {
   background: $white;
   border-radius: 20px;
   main {
     padding: 0;
   }
 }

  .felix-ai-history {
    margin-bottom: 10px;
    .ant-collapse-header {
      padding: 20px 5px 5px 5px;
    }
    .ant-menu-item {
      height: 40px;
    }
  }

  .wrapper-user-box {
    .user-box {
      background-color: #2f3334;
      width: 210px;
      margin: 9px;
      border-radius: 12px;
      padding: 20px 8px 10px 8px;
      .name {
        font-size: 13px;
        color: #fefefe;
        font-weight: bold;
      }
      .email {
        color: #bcbfc0;
        font-size: 11px;
      }
      .ant-avatar {
        margin-left: 10px;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .layout-auth {
      margin: 20px 20px 15px 0;
      border-radius: 10px;
    }
  }

}
