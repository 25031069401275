@import "../mixins";

.captcha {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  .reload-captcha {
    font-size: 26px;
    display: flex;
    cursor: pointer;
  }
  .svg-captcha {
    display: flex;
    padding-bottom: 4px;
  }
}