@import "../mixins";

.sign-up {
  margin-top: 60px;

  .ant-typography {
    font-size: 36px;
  }

  .terms-of-service {
    margin-top: -25px;
    a {
      color: #0084FF;
      font-size: 12px;
    }
    .ant-typography {
      font-size: 13px;
      font-weight: bold;
      margin-right: 5px;
    }
  }

  @media only screen and (max-width: 1199px) {
    margin-top: 40px;
  }

  @media only screen and (max-width: 767px) {
    .ant-typography {
      font-size: 28px;
    }
  }
}