@import "../mixins";

.preloader {
  background: $theme;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
}
.spinner {
  width: 150px;
  height: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -75px 0 0 -75px;
  font-size: 10px;
  text-indent: -12345px;
  z-index: 10000;
}
.doubleBounce1,
.doubleBounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.doubleBounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
