@import "../mixins";

.payment {
  background: linear-gradient(#faf8f8, #f6f4f4)top/100% 1100px no-repeat;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 40px;

  .content-wrapper {
    .content {
      margin: auto;
      padding: 70px 30px;
      min-height: 100vh;
      .plan.basic {
        .ant-typography {
          span {
            color: #151515;
          }
          svg {
            color: #abadad;
          }
        }
        span.ant-typography {
          color: #F5F6F6;
        }
        .ant-input-number-affix-wrapper {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          .ant-input-number-prefix {
            font-weight: bold;
            font-size: 19px;
          }
          input {
            font-size: 19px;
            padding-left: 10px;
          }
        }
      }
      .plan.premium {
        .ant-tag {
          padding: 0 12px;
        }
        .ant-typography {
          span {
            color: #151515;
          }
          svg {
            color: #abadad;
          }
        }
      }
      .plan.prime {
        h3 {
          color: #0084FF;
          margin-bottom: 0;
        }
        .ant-typography {
          span {
            color: #151515;
          }
          svg {
            color: #abadad;
          }
        }
      }
      .plan {
        h3 {
          font-size: 24px;
          color: #141718;
          margin-top: 0;
          text-align: left;
        }
        h4 {
          font-size: 36px;
          color: #141718;
          margin-top: 0;
          margin-bottom: 0;
          span {
            font-size: 22px;
            color: #adadad !important;
          }
        }
        h5 {
          font-size: 13px;
          font-weight: normal;
          margin-top: 0;
        }
        background-color: #e3e3e3;
        border: 1px solid #b9b9b9;
        border-radius: 25px;
        padding: 25px 20px;
        margin-bottom: 20px;
      }
      .frequently {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .collapse {
        margin-top: 150px;
        .ant-collapse {
          max-width: 900px;
          margin: auto;
          background: transparent;
          .ant-collapse-item {
            border-top: 1px solid #dedede;
            .ant-collapse-header {
              color: #232627;
            }
            .ant-collapse-content {
              .ant-typography {
                color: #6C7275;
              }
            }
          }
        }
      }
    }

    h1 {
      font-size: 38px;
      font-weight: bold;
      color: #141718;
      margin-bottom: 10px;
      text-align: center;
    }

    h2 {
      font-size: 16px;
      color: #a2a2a2;
      margin-top: 10px !important;
      text-align: center;
    }

    h3 {
      font-size: 16px;
      color: #141718;
      margin-top: 10px !important;
      text-align: center;
    }

  }
}

.payment-success,
.payment-error {
  max-width: 600px;
  margin: 150px auto auto;
  .ant-result {
    .ant-result-icon,
    .ant-result-title,
    .ant-result-subtitle,
    .ant-result-extra {
      text-align: left;
    }
    .ant-result-title {
      color: #141718;
    }
    .ant-result-subtitle {
      color: #6C7275;
    }
    .ant-result-extra {
      display: block;
    }
  }
}