@import "../mixins";

.dashboard {
  padding: 60px 40px;
  .title {
    max-width: 600px;
    margin: auto;
    display: flex;
    text-align: center;
    h1 {
      font-size: 42px;
      font-weight: bold;
      margin-bottom: 0;
    }
    h2 {
      font-size: 22px;
      color: #6C7275;
      margin-bottom: 20px;
    }
  }
  .tender {
    background-color: #f6f7f8;
    height: 100px;
    display: flex;
    border: 3px solid #e8e8e8;
    border-radius: 12px;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    h4 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0;
    }
    .flag {
      width: 60px;
      height: 60px;
      background-color: #FEFEFE;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
    }
    .ant-btn {
      height: 32px;
    }
  }
}