@import "../../mixins";

.opportunities {
  .sider-left-border {
    border-right: 1px solid #E8ECEF;
  }
  .search {
    display: block;
    width: 100%;
    min-height: 100vh;
    .ant-input-outlined {
      input {
        font-size: 20px;
        padding-left: 5px;
      }
      .ant-btn {
        background: $white !important;
        border: 0 !important;
        padding: 25px;
        svg {
          color: #c9cbcc;
        }
      }
      padding: 0 !important;
      border: 0 !important;
    }
    .search-form {
      width: 100%;
      padding: 20px 30px 40px 30px;
      .search-button {
        width: 100%;
        justify-content: end;
      }
    }
    .search-result {
      padding: 10px 20px 30px 20px;
      .ant-card {
        margin-bottom: 20px;
        background-color: transparent;
        border-color: #c9cbcc;
        color: #F5F6F6;
        border-radius: 20px;
        .ant-card-head-title {
          color: $black;
          white-space: normal;
          .ant-tag {
            color: $black;
            font-weight: bold;
            border-radius: 10px;
            font-size: 13px;
            padding: 8px;
          }
        }
        .ant-card-head {
          border-color: #c9cbcc;
          padding: 20px 30px 5px 30px;
          border-bottom: 0;
          .search-icon {
            color: #00899B;
            span {
              font-size: 14px;
            }
          }
        }
        .ant-card-body {
          .search-tag {
            height: 125px;
            align-items: start;
            width: 100%;
            .ant-tag {
              color: $black;
              font-weight: bold;
              border-radius: 10px;
              font-size: 13px;
              padding: 8px;
              margin-bottom: 10px;
            }
            .ant-typography:last-child {
              font-size: 22px;
            }
          }
          .search-button {
            width: 100%;
            justify-content: end;
          }
        }
        .ant-typography {
          color: $black;
          font-size: 14px;
          font-weight: 100;
          min-height: 20px;
        }
        h1, .title {
          font-size: 16px !important;
          font-weight: bold !important;
          cursor: pointer;
        }
        h2 {
          font-size: 16px !important;
          font-weight: bold !important;
          color: #6C7275 !important;
        }
        .description {
          height: 100px;
          overflow: hidden;
          color: #000000;
          font-size: 14px;
          font-weight: 100;
          position: relative;
          -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0));
          mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0));
        }
      }
      .ant-tag {
        display: inline-flex;
        .ellipsis {
          display: inline-block;
          margin-inline-end: 4px;
          overflow: hidden;
          white-space: pre;
          text-overflow: ellipsis;
          max-width: 400px;
          font-size: 13px;
          color: $black;
        }
      }
    }
    .ant-pagination {
      margin: 5px 10px 50px 10px;
      font-weight: bold;
      .ant-pagination-prev, .ant-pagination-next {
        a {
          padding: 8px;
        }
      }
      .ant-pagination-item-active {
        a {
          background-color: $theme !important;
          color: $white;
        }
      }
    }
    .save-and-like {
      svg {
        color: #7e7d7d;
        cursor: pointer;
      }
    }
  }

  .filter {
    display: block;
    width: 100%;
    box-shadow: 15px 40px 35px -30px #eae8e8 inset;
    .title {
      font-size: 17px;
      font-weight: 600;
      color: $black;
      padding: 16px 24px 16px 24px;
      border-bottom: 1px solid #E8ECEF;
      svg {
        cursor: pointer;
        color: #6C7275;
      }
    }
    .filter-item {
      width: 100%;
      height: 90vh;
      overflow-y: auto;
      padding: 15px;
      .ant-form {
        .ant-form-item-label {
          label {
            color: $black;
            font-weight: bold;
          }
        }
      }
    }
    .tags {
      line-height: 2.8;
      overflow: auto;
      .ant-tag {
        padding: 3px 10px;
        border-radius: 10px;
        font-size: 13px;
        color: $black;
        font-weight: bold;
        display: inline-flex;
        .ellipsis {
          display: inline-block;
          margin-inline-end: 4px;
          overflow: hidden;
          white-space: pre;
          text-overflow: ellipsis;
          max-width: 200px;
          font-size: 13px;
          color: $black;
        }
        .ant-tag-close-icon {
          margin-inline-start: 6px;
          font-size: 13px;
          color: #726f6f;
        }
      }
    }
    .ant-select-multiple {
      .ant-select-selection-overflow-item {
        max-width: 230px;
      }
    }
  }
}

.opportunities {
  .ant-empty {
    .ant-empty-description {
      font-size: 18px;
      margin-top: 20px;
      font-weight: 600;
    }
  }
}