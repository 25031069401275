@import "../mixins";

.public-layout {
  background-color: $background !important;
  min-height: 100vh;

  main {
    padding: 40px;
  }

  footer {
    background-color: $background !important;
    text-align: center;
    color: #F5F6F6;
  }
}
