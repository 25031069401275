@import "../../mixins";

.profile {
  .ai-files {
    .tags {
      line-height: 2.8;
      .ant-tag {
        padding: 3px 10px;
        border-radius: 10px;
        font-size: 15px;
        color: $black;
        font-weight: bold;
        display: inline-flex;
        .ant-tag-close-icon {
          margin-inline-start: 6px;
          font-size: 13px;
          svg {
            color: #726f6f;
          }
        }
      }
    }
    .upload-wrapper {
      padding: 15px;
      background-color: #E8ECEF;
      border-radius: 20px;
      .ant-upload-wrapper {
        .ant-upload-drag {
          border: 2px dashed #c3c5c5 !important;
          border-radius: 20px;
          .ant-upload {
            .ant-upload-text {
              color: #343839;
            }
            .ant-upload-hint {
              color: #6C7275;
              font-weight: bold;
            }
            .ant-upload-drag-icon {
              width: 75px;
              height: 75px;
              margin: 40px auto 30px auto;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #F5F6F6;
              border-radius: 50%;
              svg {
                color: #343839;
              }
            }
            .ant-upload-text {
              font-weight: bold;
              font-size: 38px;
              margin-bottom: 10px;
            }
            .ant-upload-hint {
              font-weight: normal;
              font-size: 16px;
              margin-top: 0;
              margin-bottom: 40px;
            }
          }
        }
      }
    }
    h2 {
      margin:0;
    }
  }
}