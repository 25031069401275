@import "../mixins";

.intro {
  background-image: url("../../media/intro-bg.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 636px;
  height: 830px;
  margin: auto;
  .intro-title {
    text-align: left;
    margin-top: 40px;
    h1 {
      color: $white;
      font-size: 36px;
      font-weight: bolder;
    }
    p {
      font-size: 18px;
      max-width: 350px;
      color: #E8ECEF80;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 310px;
    height: 405px;
    .intro-title {
      margin-top: 5px;
    }
  }
}