@import "../../mixins";

.profile {
  .classifications {
    .tags {
      line-height: 2.8;
      .ant-tag {
        padding: 3px 10px;
        border-radius: 10px;
        font-size: 15px;
        color: $black;
        font-weight: bold;
        display: inline-flex;
        .ant-tag-close-icon {
          margin-inline-start: 6px;
          font-size: 13px;
          svg {
            color: #726f6f;
          }
        }
      }
    }
  }
}