@import "../../mixins";

.profile {
  .my-account {
    .switch {
      font-weight: bold;
      color: #6C7275;
    }
    .ant-upload-wrapper {
      .ant-upload {
        width: 100% !important;
        .ant-avatar {
          cursor: pointer;
        }
        .ant-typography {
          font-size: 14px;
          color: #6C7275;
        }
      }
    }
    h2 {
      margin: 40px 0;
    }
  }
}