@import "mixins";

html {
  scroll-behavior: smooth;
}

body {
  font-family: $base__font-family;
}

.button-grey {
  background: #eeeeee;
  color: #444444;
}

.button-grey:hover {
  color: #414040 !important;
  background: #dcdcdc !important;
}

.button-green {
  background: #3FDD78;
  color: #444444;
}

.button-green:hover {
  color: #414040 !important;
  background: #24b258 !important;
}

.button-black {
  background: #1e1d1d;
  color: #f5f4f4;
}

.button-black:hover {
  color: #e7e5e5 !important;
  background: #090909 !important;
}

.button-red {
  background: #FF6C3E;
  color: #f5f4f4;
}

.button-red:hover {
  color: #e7e5e5 !important;
  background: #fa450c !important;
}

.link-button.white {
  span {
    color: $white;
  }
}

.link-button {
  display: inline-flex;
  border: solid 2px #dcdede;
  float: right;
  padding: 10px 20px;
  border-radius: 20px;
  line-height: 0;
  justify-content: center;
  .white {
    color: $white;
  }
  .red {
    color: #FF6C3E;
  }
  span {
    color: $black;
  }
  .anticon-loading {
    svg {
      color: #ffffff !important;
      font-weight: bold !important;
    }
  }
  svg {
    color: $black;
  }
}

.link-button-black {
  border-color: #636565;
}

.link-button-primary {
  color: #fff;
  background: #0084ff;
  box-shadow: 0 2px 0 rgba(5, 175, 255, 0.1);
}

.link-button-primary:hover {
  color: #fff !important;
  background: #2894f8 !important;
  box-shadow: 0 2px 0 rgba(5, 175, 255, 0.1);
}

.link-button-red {
  border-color: #bb5d3e;
}

.link-button:hover {
  span {
    color: #a2a2a2;
  }
  svg {
    color: #a2a2a2;
  }
}

.link-button-red:hover {
  span {
    color: #ea582a;
  }
  svg {
    color: #ea582a;
  }
}

.link-button.block {
  width: 100%;
  padding: 13px 20px;
}

.link-button.active {
  border: solid 2px #F5F6F6;
  span, svg {
    color: #F5F6F6;
  }
}

.ant-modal-ai-files, .ant-modal-members {
  .ant-modal-content {
    padding: 10px 20px !important;
  }
  .ant-modal-body {
    margin: 15px auto 20px auto !important;
    svg {
      color: #0084FF;
    }
    .ant-modal-confirm-title {
      color: #F5F6F6;
    }
  }
}

.suggestions-modal, .ant-modal-ai-files, .ant-modal-invite-member, .ant-modal-members {
  .ant-modal-content {
    background: $white;
    color: #141718;
    .tags {
      line-height: 2.8;
      padding: 10px 8px;
      border: 1px solid #d5d2d2;
      border-radius: 10px;
      .ant-tag {
        padding: 3px 10px;
        border-radius: 10px;
        font-size: 15px;
        color: $black;
        font-weight: bold;
        .ant-tag-close-icon {
          margin-inline-start: 6px;
          font-size: 13px;
          svg {
            color: #726f6f;
          }
        }
      }
      .tag-add {
        cursor: pointer;
        font-weight: normal;
      }
    }
    .ant-modal-header {
      background: $white;
      .ant-modal-title {
        color: #141718;
      }
    }
    .ant-modal-confirm-title {
      color: #141718;
    }
    h2 {
      color: #141718 !important;
    }
    .ant-form {
      .ant-form-item-extra {
        span {
          color: #8f8e8e;
        }
      }
    }
  }
  .ant-modal-close {
    background: $white !important;
    svg {
      font-size: 18px !important;
      font-weight: bold;
    }
  }
}

.terms-of-service-modal, .suggestions-modal {
  .ant-modal-content {
    .ant-modal-body {
      .ant-modal-body-content {
        height: 300px;
        overflow-y: auto;
      }
      h2 {
        font-size: 16px;
        color: #F5F6F6;
      }
      .tags {
        line-height: 2.8;
        min-height: 100px;
        .ant-tag {
          padding: 3px 10px;
          border-radius: 10px;
          font-size: 15px;
          font-weight: bold;
          .ellipsis {
            display: inline-block;
            margin-inline-end: 4px;
            overflow: hidden;
            white-space: pre;
            text-overflow: ellipsis;
            max-width: 300px;
            font-size: 13px;
            color: $black;
          }
          .ant-tag-close-icon {
            margin-inline-start: 6px;
            font-size: 13px;
            svg {
              color: #726f6f;
            }
          }
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      gap: 10px;
    }
    .ant-btn:disabled {
      color: #F5F6F6;
      border-color: #787979;
    }
  }
}

.ellipsis {
  max-width: 500px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wrapper-white {
  background-color: $white;
  border-radius: 20px;
  padding: 10px;
}

.disabled {
  svg {
    color: #cecece !important;
  }
}

div.disabled {
  background-color: #cecece !important;
}

a.disabled {
  color: #cecece !important;
  cursor: default !important;
}

@media only screen and (max-width: 1199px) {
  .link-button-mobile {
    max-width: 400px;
    display: flex;
    justify-content: center;
    margin: 20px auto;
    float: none;
    width: 100%;
  }
}
