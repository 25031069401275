@import "../../mixins";

.profile {
  .delete-account {
    h2 {
      font-size: 18px !important;
      font-weight: normal;
      margin: 0;
    }
  }
}