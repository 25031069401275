@import "../mixins";

.reset-password {
  margin-top: 300px;

  .ant-typography.notice {
    font-size: 15px;
    font-weight: bold;
  }

  .ant-typography {
    font-size: 24px;
    color: #141718;
  }

  @media only screen and (max-width: 1199px) {
    margin-top: 40px;
  }

  @media only screen and (max-width: 767px) {
    .ant-typography {
      font-size: 28px;
    }
  }
}
