@import "mixins";

.ant-layout-content {
  background: transparent !important;
}

.ant-tabs {
  .ant-tabs-content {
    line-height: 3;
    p {
      font-size: 15px;
    }
  }
  .ant-tabs-tab-btn {
    font-size: 14px;
    font-weight: bold;
  }
  .ant-tabs-nav {
    margin-bottom: 30px;
  }
}

.ant-form {
  .ant-form-item-label {
    label {
      color: #232627;
      font-weight: bold;
    }
    label::before {
      content: none !important;
    }
  }
  .ant-form-item-explain {
    margin-top: 5px;
    font-size: 12px;
    font-weight: bold;
  }
  .ant-form-item-extra {
    margin-top: 5px;
    margin-bottom: 5px;
    span {
      color: $black;
      font-size: 13px;
    }
    a {
      color: #0084FF;
      font-size: 12px;
      font-weight: bold;
    }
    a:hover {
      color: #10b8ce;
    }
  }
}

.ant-btn {
  height: 42px;
  border-radius: 12px;
  font-weight: bold;
}

.ant-select {
  .ant-select-selector {
    background: #F5F5F5 !important;
    border: 0 !important;
    border-radius: 10px;
    line-height: 1.9;
    color: #9a9b9b;
    font-weight: bold;
    font-size: 14px !important;
    box-shadow: none !important;
    .ant-select-selection-item, .ant-select-selection-search, .ant-select-selection-placeholder {
      color: #9a9b9b;
    }
    .ant-select-selection-item-remove {
      color: #0084FF !important;
      font-size: 12px !important;
    }
  }
  .ant-select-arrow, .ant-select-clear {
    color: #0084FF !important;
    font-size: 16px;
    font-weight: bold;
  }
}

.ant-select-single {
  height: 46px !important;
  input {
    height: 46px !important;
  }
}

.ant-select-multiple {
  .ant-select-selector {
    .ant-select-selection-search-input {
      height: 46px !important;
    }
  }
  .ant-select-selection-item-content {
    color: $black;
  }
}

.ant-select-dropdown {
  background: #F3F5F7 !important;
  border-radius: 10px;
  border: 2px solid #d9d9d9 !important;
  .ant-select-item {
    .ant-select-item-option-content {
      font-size: 13px;
      font-weight: bold;
      color: #9a9b9b;
    }
    .ant-select-item-option-state {
      font-weight: bold;
      font-size: 18px;
      color: #F5F6F6 !important;
    }
  }
  .ant-select-item-option-selected {
    background: #0084FF !important;
    .ant-select-item-option-content {
      color: #F5F6F6 !important;
    }
  }
  .rc-virtual-list-scrollbar {
    .rc-virtual-list-scrollbar-thumb {
      background: #0084FF !important;
    }
  }
  .ant-empty-image, .ant-empty-description {
    font-weight: bold;
    color: #9a9b9b !important;
  }
}

.ant-checkbox-wrapper {
  font-size: 14px;
  color: $black;
  font-weight: bold;
  .ant-checkbox-inner {
    border-radius: 5px;
    width: 23px;
    height: 23px;
  }
  .ant-checkbox-inner::after {
    width: 8px;
    height: 14px;
  }
}

.ant-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  label {
    font-size: 14px;
    color: #F5F6F6;
    font-weight: bold;
  }
  .ant-checkbox-inner {
    border: 2px solid #0084FF !important;
    border-radius: 5px;
    background: $theme;
    width: 23px;
    height: 23px;
  }
  .ant-checkbox-inner::after {
    width: 8px;
    height: 14px;
  }
}

.ant-picker {
  width: 100%;
  .ant-picker-selection-placeholder {
    color: #9a9b9b !important;
  }
  .ant-picker-suffix, .ant-picker-clear {
    color: #0084FF !important;
    .anticon {
      font-size: 20px;
    }
  }
  .ant-picker-selection-item-remove {
    color: #0084FF !important;
    font-size: 11px;
  }
  .ant-picker-input {
    input {
      font-size: 13px;
    }
  }
  .ant-picker-input-placeholder {
    input {
      color: #9a9b9b !important;
    }
  }
}

.ant-picker, .ant-input, .ant-input-outlined, .ant-picker-outlined {
  border-radius: 10px;
  line-height: 1.9;
  color: #6C7275;
  font-weight: bold;
  font-size: 14px;
  padding: 8px 15px;
  box-shadow: none !important;
}

.ant-picker-input {
  input::placeholder {
    font-weight: bold !important;
    font-size: 14px !important;
    color: #babcbd !important;
    opacity: 1 !important; /* Firefox */
  }

  input::-ms-input-placeholder { /* Edge 12-18 */
    font-weight: bold !important;
    font-size: 14px !important;
    color: #babcbd !important;
  }
}

.ant-input-affix-wrapper {
  .ant-input-prefix {
    svg {
      margin-right: 5px;
    }
  }
  .ant-input-suffix {

  }
}

.ant-input::placeholder {
  color: #babcbd;
  opacity: 1; /* Firefox */
}

.ant-input::-ms-input-placeholder { /* Edge 12-18 */
  color: #babcbd;
}

.ant-input-lg {
  font-size: 14px;
}

.ant-empty {
  .ant-empty-description {
    color: #9a9b9b;
  }
}

.ant-spin {
  color: #9a9b9b !important;
}

.ant-modal {
  .ant-modal-content {
    padding: 30px 34px;
    border-radius: 20px;
    background: #141718;
    color: #F5F6F6;
    .ant-modal-header {
      background: #141718;
      .ant-modal-title {
        color: #F5F6F6;
        font-weight: bold;
        font-size: 22px;
      }
    }
    .ant-modal-close {
      top: 30px;
      right: 35px;
      background: #363434;
      border-radius: 50%;
      svg {
        color: #F5F6F6;
        font-size: 13px;
      }
    }
    .ant-modal-body {
      color: #F5F6F6;
      font-size: 14px;
      font-weight: bold;
      margin: 30px auto 40px auto;
    }
    .ant-modal-body-content::-webkit-scrollbar-track {
      border: 1px solid $white;
      padding: 2px 0;
      background-color: $white;
      border-radius: 30px;
    }
    .ant-modal-body-content::-webkit-scrollbar {
      width: 7px;
    }
    .ant-modal-body-content::-webkit-scrollbar-thumb {
      border-radius: 30px;
      box-shadow: inset 0 0 6px #2a2c2d;
      background-color: #141718;
      border: 1px solid #2a2c2d;
    }
  }
}

.ant-result {
  .ant-result-title {
    font-size: 28px;
    font-weight: bold;
    color: #F5F6F6;
  }
  .ant-result-subtitle {
    color: #F5F6F6;
    font-size: 16px;
  }
  .ant-result-extra {
    display: flex;
    justify-content: center;
  }
}

.ant-divider {
  border-color: #d4d5d5;
}

.ant-divider-horizontal {
  margin: 20px 0;
}

.ant-select-dropdown {
  h3.ant-typography {
    font-size: 12px;
    color: #9a9b9b;
  }
  h4.ant-typography {
    font-size: 11px;
    color: #6f7070;
  }
}

.ant-spin-nested-loading {
  .ant-spin {
    .ant-spin-text {
      color: #0c0c0c;
      font-size: 16px;
      margin-top: 10px;
    }
  }
}

.ant-switch.ant-switch-checked {
  background: #0084FF !important;
}

.ant-collapse {
  .ant-collapse-item {
    border-top: 1px solid #575757;
    border-bottom: 0;
    .ant-collapse-header {
      padding: 20px 5px;
      color: #F5F6F6;
      font-weight: bold;
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 4px 15px 15px 30px;
      }
      .ant-typography {
        color: #F5F6F6;
        line-height: 2;
        margin-bottom: 5px;
        font-weight: normal;
      }
    }
  }
}

.ant-table-wrapper {
  .ant-table {
    .ant-table-thead {
      tr {
        th {
          background: transparent;
        }
      }
    }
    .ant-table-tbody {
      tr {
        td {
          color: #6C7275;
          font-weight: bold;
        }
      }
    }
  }
}