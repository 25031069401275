@import "../../mixins";

.profile {
  .members {
    .member {
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span.ant-typography {
        font-size: 14px;
        font-weight: bold;
      }
      .icon-trash {
        cursor: pointer;
        margin-right: 20px;
      }
    }
    .ant-divider {
      border-color: #eee;
    }
    h2 {
      font-size: 18px !important;
      color: #aca8a8 !important;
    }
    .ant-tag {
      color: $black;
      font-weight: bold;
    }
    .email {
      color: #949191;
      font-weight: normal !important;
    }
  }
}